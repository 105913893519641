//main.js

	slider = $("#slider").bxSlider({
		startSlide: 1,
		controls: false,
		pager: false,
		touchEnabled: false,
		infiniteLoop: false,
		speed: 250,
		onSliderLoad: function(){
			$("#slider").addClass("visible");
		}
	});

	$(".back").click(function(e) {
		e.preventDefault();

		slider.goToSlide(1);
	});


	$("#digitalToggle").click(function(e) {
		e.preventDefault();

		slider.goToSlide(0);
	});

	$("#creativeToggle").click(function(e) {
		e.preventDefault();
		slider.goToSlide(2);
	});
